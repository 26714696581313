import React, {useEffect, useState} from "react";
import {LogoHeader} from "../layouts/LogoHeader";
import {
    Backdrop, Box,
    Button, capitalize,
    Card,
    CircularProgress, Collapse,
    Container,
    Snackbar,
    Stack, TextareaAutosize,
    TextField,
    Typography
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export const Form = () => {
    const {id} = useParams()
    const [fields, setFields] = useState([])
    let navigate = useNavigate()
    const [values, setValues] = useState({
        domanda: ''
    })
    const [loading, setLoading] = useState(true)
    const [success, setSuccess] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(false)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        axios.get('https://api-tool-press-yn26wrnl4q-ey.a.run.app/conference/' + id + '/columns')
            .then(response => {
                const obj = {}
                setFields(response.data)
                response.data.map(r => obj[r] = '')
                setValues({...values, ...obj})
                setLoading(false)
            })
            .catch(() => {
                navigate("/", {replace: true})
            })
    }, [])

    const handleChangeValue = (e, key) => {
        setValues({...values, [key]: e.target.value})
    }

    const postQuestions = async () => {
        setSending(true)
        await axios.post('https://api-tool-press-yn26wrnl4q-ey.a.run.app/question/' + id, {question: values})
        setSending(false)
        setValues(prevState => ({...prevState, domanda: ''}))
    }


    const sendQuestion = (e) => {
        e.preventDefault()

        postQuestions()
            .then(() => {
                setSuccess(true)
                setOpen(true)
                setTimeout(() => setOpen(false),5000)
            })
            .catch(() => setError(true))

    }

    const handleSetError = (bool) => {
        setSuccess(bool)
    }


    return (
        <LogoHeader>
            <Snackbar open={error} autoHideDuration={6000} onClose={() => handleSetError(false)}>
                <Alert onClose={() => handleSetError(false)} severity="error" sx={{width: '100%'}}>
                    C'è stato un errore, si prega di riprovare
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loading || sending}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>

            <Container maxWidth={'sm'}>
                <Typography variant={"h4"} fontWeight={'bold'} sx={{my: 5}} textTransform={'uppercase'} color={'white'}>
                    Invia la tua domanda
                </Typography>
                <Card variant={'gradient'}>
                    <form style={{padding: '1rem 1rem', textAlign: 'left'}} onSubmit={sendQuestion}>
                        <Stack direction={"column"} spacing={4}>
                            {fields.map(f =>
                                <Box>
                                    <TextField sx={{width: '100%'}} variant={"standard"}
                                               label={capitalize(f)}
                                               required
                                               value={values[f]} onChange={(e) => handleChangeValue(e, f)}
                                    />
                                </Box>
                            )}
                            <Box sx={{width: '100%'}}>
                                <Typography variant={'h6'}>Domanda</Typography>
                                <TextareaAutosize required value={values['domanda']}
                                                  minRows={5}
                                                  onChange={(e) => handleChangeValue(e, 'domanda')}
                                                  style={{width: '98%', resize: 'none'}}
                                                  variant={"standard"} placeholder={'Domanda'}/>
                            </Box>
                            <Box sx={{textAlign: 'center'}}>
                                <Button sx={{my: 2}} variant={"contained"} type={"submit"}>
                                    {success ? "Invia un'altra domanda" : 'Invia domanda'}
                                </Button>
                            </Box>
                        </Stack>
                    </form>
                    <Collapse in={open}>
                        <Typography>
                            Domanda inviata con successo
                        </Typography>
                    </Collapse>
                </Card>
            </Container>
        </LogoHeader>
    )
}
