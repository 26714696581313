import React, {useState} from "react";
import {Button, Card, Container, TextField, Typography} from "@mui/material";
import {LogoHeader} from "../layouts/LogoHeader";
import {useNavigate} from "react-router-dom";

export const Home = () => {
    const [code, setCode] = useState('')
    const navigator = useNavigate()

    const handleSetCode = (e) => {
        setCode(e.target.value)
    }

    const goToForm = () => {
        navigator('/form/'+code, {replace:true})
    }

    return (
        <LogoHeader>
            <Container maxWidth={'sm'}>
                <Typography variant={"h4"} fontWeight={'bold'} sx={{my: 5}} textTransform={'uppercase'} color={'white'}>
                    Inserisci il codice della conferenza
                </Typography>
                <Card variant={'gradient'} sx={{p:2}}>
                    <TextField fullWidth variant={"standard"} onChange={handleSetCode} label={'Codice'}/>
                    <Button variant={"contained"} sx={{my:2}} onClick={goToForm}>
                        Entra
                    </Button>
                </Card>
            </Container>
        </LogoHeader>
    )
}
