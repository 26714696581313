import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import {Home} from "./pages/Home";
import {Form} from "./pages/Form";

function App() {
  return (
    <div>
      <Routes>
        <Route path={'/'} element={<Home/>}/>
          <Route path={'/form/:id'} element={<Form/>}/>
      </Routes>
    </div>
  );
}

export default App;
