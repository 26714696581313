import React from "react";
import {Box} from "@mui/material";
import logo from '../assets/logo_toolpress.png'

export const LogoHeader = ({children}) => {
    return(
        <Box sx={{textAlign:'center', width:'100%', mt:10}}>
            <img src={logo} style={{width:'100%',maxWidth:'17.563rem'}}/>
            {children}
        </Box>
    )
}
